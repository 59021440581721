import React, { useMemo } from 'react';
import axios from 'axios';
import { CoursesContainer, FlexItem, StyledTH } from '@/styles/Containers';
import styled from 'styled-components';
import API_URL from '@/config';
import Course from './Course';
import Loading from '../Loading';
import buildQueryString from '@/utils/buildQueryString';
import { getOrderByDirection, getOrderByLabel, getOrderByResultMetric, OrderByOption } from '@/types/query';
import { usePaginationContext } from '@/context/PaginationContext';
import { useQuery } from '@tanstack/react-query';
import { CustomizableHeading, GoBackText } from '@/styles/Text';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CourseType } from '@/models/course';

interface CourseListType {
  count: number;
  data: CourseType[];
}

export const EmptyResult = styled.h3`
  text-align: center;
  align-self: center;
  grid-column: 1/-1;
`;

const resultLimit = 24;
export const StyledCourseTable = styled.table`
  display: none;
  width: 100%;
  @media ${({ theme }) => theme.device.mobileL} {
    display: block;
  }
`;

const useCourseListData = () => {
  const { coursePagination, setCoursePagination } = usePaginationContext();
  const [searchParams] = useSearchParams();
  const inputValue = searchParams.get('inputValue') ?? '';
  const sortOption = searchParams.get('sortOption') ?? 'review_count';
  const direction = getOrderByDirection(sortOption as OrderByOption);
  const advancedSorting = searchParams.get('advanced') ?? 'false';
  const difficultyWeight = searchParams.get('difficulty_weight') ?? 0;
  const gradeWeight = searchParams.get('grade_weight') ?? 0;
  const passRateWeight = searchParams.get('pass_rate_weight') ?? 0;
  const workloadWeight = searchParams.get('workload_weight') ?? 0;
  const scoreWeight = searchParams.get('score_weight') ?? 0;

  const searchOptions = {
    n: resultLimit,
    search: inputValue,
    advanced_sorting: advancedSorting,
    offset: (coursePagination.currentPage - 1) * resultLimit,
    order_by: sortOption,
    direction,
    difficulty_weight: difficultyWeight,
    grade_weight: gradeWeight,
    pass_rate_weight: passRateWeight,
    workload_weight: workloadWeight,
    score_weight: scoreWeight,
  };

  return useQuery<CourseType[]>(['course-list', searchOptions], async () => {
    const response = await axios.get(`${API_URL}/course/all/${buildQueryString({ ...searchOptions })}`);
    const { data, count } = response.data as CourseListType;
    setCoursePagination((pagination) => ({ ...pagination, totalPages: Math.ceil(count / resultLimit) }));
    return data;
  });
};

export const CourseList = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sortOption = searchParams.get('sortOption') ?? 'review_count';
  const inputValue = searchParams.get('inputValue') ?? '';
  const advancedSorting = searchParams.get('advanced') ?? 'false';
  const isOnSearchPage = useLocation().pathname === '/search';

  const { isLoading, isError, data } = useCourseListData();

  const getLabel = useMemo(() => {
    return getOrderByResultMetric(sortOption as OrderByOption);
  }, [sortOption]);

  if (isLoading) return <Loading />;

  if (isError) return <div style={{ margin: '3rem auto' }}>Noe gikk galt...</div>;

  if (data && data?.length === 0) {
    return <NoCoursesResults searchTerm={inputValue} />;
  }

  return (
    <>
      {isOnSearchPage && (
        <>
          <FlexItem margin='0 0 0 0' onClick={() => navigate(-1)}>
            <GoBackText>Tilbake</GoBackText>
          </FlexItem>
          <CustomizableHeading fontSize='1.25em' margin='0px' headingLevel='h1'>
            Søk: &quot;{inputValue}&quot;
          </CustomizableHeading>
        </>
      )}
      {isOnSearchPage && (
        <CustomizableHeading headingLevel='h2' option='subtitle' fontWeight='400'>
          Sortert basert på {getOrderByLabel(sortOption as OrderByOption).toLowerCase()}
        </CustomizableHeading>
      )}
      <span id='søkeresultater' />

      <StyledCourseTable>
        <thead>
          <tr>
            <StyledTH width='15%'>Emnekode</StyledTH>
            <StyledTH width='75%' style={{ paddingLeft: '3%' }} textAlign='left'>
              Emnenavn
            </StyledTH>
            <StyledTH width='25%'>{getLabel}</StyledTH>
          </tr>
        </thead>
      </StyledCourseTable>
      <CoursesContainer>
        {data && data.length ? (
          data.map((currentCourse) => (
            <Course
              key={currentCourse.course_code}
              courseCode={currentCourse.course_code}
              courseName={currentCourse.course_name_norwegian}
              averageReviewScore={currentCourse.average_review_score}
              reviewCount={currentCourse.review_count}
              averageGrade={currentCourse.average_grade}
              passRate={currentCourse.pass_rate}
              credit={currentCourse.credit}
              advancedSortingMatch={currentCourse.advanced_sorting_score}
              sortingParam={advancedSorting == 'true' ? 'advanced_sorting_score' : sortOption}
            />
          ))
        ) : (
          <EmptyResult>Beklager! Vi fant ingen data. </EmptyResult>
        )}
      </CoursesContainer>
    </>
  );
};

function NoCoursesResults({ searchTerm }: { searchTerm: string }) {
  return (
    <>
      <h1>Ingen Resultater</h1>
      <p>Ditt søk etter &lsquo;{searchTerm}&lsquo; gav ingen resultater.</p>
      <section>
        <h2>Søketips</h2>
        <p>Husk at du kan søke etter</p>
        <ul>
          <li>Emnekode (f.eks TMA4100)</li>
          <li>Emnenavn (f.eks. Matematikk 1)</li>
        </ul>
      </section>
    </>
  );
}
